
































































import { Component, Vue  } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";

@Component({
  name: "Login",
  components: {},
})

export default class extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };
  private valid = false;
  private loading = false;
  private show1 = false;
  private password = "";
  private confirmPassword = "";



  private passwordRules = [
    (v: string) => !!v || "رمز عبور   الزامی میباشد",
    (v: string) => v.length > 5 || "رمز عبور   باید 6 رقم باشد ",
  ];

    private confirmPasswordRules = [
    (v: string) => !!v || "لطفا تکرار رمز عبور را وارد نمایید"]

  get compareConfirmPasswordRules(){
       return this.password === this.confirmPassword ||
        "تکرار رمز عبور اشتباه است";
  }
  private async changePassword() {
    this.validationForm();
    if (!this.valid) return;

    this.loading = true;
    try {
      await UserModule.ChangePassword({
        password: this.password,
        confirmPassword: this.confirmPassword,
      });
      this.loading = false;
      this.$router.push("/login");
    } catch (error) {
      this.loading = false;
    }
  }
  private validationForm() {
    this.$refs.form.validate();
  }
}
